import React, {useContext} from "react";
import "./skillChart.css";
import {coderRankSkillChart} from "../../portfolio";
import CodersRankSkillsChart from "@codersrank/skills-chart";
import StyleContext from "../../contexts/StyleContext";

// register web component as <codersrank-skills-chart> element
window.customElements.define("codersrank-skills-chart", CodersRankSkillsChart);

export default function Skillchart() {
  const {isDark} = useContext(StyleContext);
  if (coderRankSkillChart.display) {
    return (
      <div className="skill-chart-section" id="skill-chart">
        <h1 className="skill-chart-heading">Timeline Skill Chart</h1>
        <div className="skill-chart-card-container">
          <codersrank-skills-chart
            username={coderRankSkillChart.userName}
            labels={true}
            skills={
              "JavaScript, React, TypeScript, JSON, TSQL, PHP, CSS, HTML, SCSS, Nodejs, Express"
            }
            legend={true}
            branding={false}
          ></codersrank-skills-chart>
        </div>
        <p
          className={
            isDark
              ? "dark-mode skill-chart-text-subtitle"
              : "subTitle skill-chart-text-subtitle"
          }
        >
          This chart is calculated based on open-source and public repository
          contributions on Github. Private repositories such as the ones from
          working at Upstart are not taken in the consideration. So, there is no
          Ruby on Rails, etc. In this chart, Reactjs, Nodejs, Expressjs are
          considered a generic JavaScript skill.
        </p>
      </div>
    );
  }
  return null;
}
